:root {
  --primary: #0a4927;
  --secondary: #179a89;
  --tertiary: rgba(255, 214, 0, 1);
}

body {
  font-family: "Be Vietnam", sans-serif;
}
.section__header {
  font-size: 40px;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .section__header {
    font-size: 30px;
  }
}
