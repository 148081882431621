:root {
  --mdPrimary: #0a4927;
  --mdSecondary: #179a89;
  --mdTertiary: #ffd600;
}
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
.app {
  min-height: 100vh;
}
.form-control {
  min-height: 45px;
}
.pointer {
  cursor: pointer;
}

.bg-mdPrimary {
  background-color: var(--mdPrimary);
}

.text-mdPrimary {
  color: var(--mdPrimary);
}
.text-mdSecondary {
  color: var(--mdSecondary);
}
.text-mdTerTiary {
  color: var(--mdTertiary);
}

/* buttons */
.btn-mdSecondary {
  background-color: var(--mdSecondary);
  transition: 0.3s;
}
.btn-mdSecondary:hover {
  background-color: var(--mdPrimary);
  transition: 0.3s;
}
.btn-outline-mdTertiary {
  background-color: transparent !important;
  border: 4px solid var(--mdTertiary);
  transition: 0.3s;
}
.btn-outline-mdTertiary:hover {
  background-color: var(--mdTertiary) !important;
}

h1.dashboard-header {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  line-height: 44px;
  color: rgba(23, 154, 137, 1);
  position: relative;
}
h1.dashboard-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 320px;
  height: 3px;
  background: var(--tertiary);
}
@media screen and (max-width: 700px) {
  h1.dashboard-header {
    font-size: 27px;
  }
}
@media screen and (max-width: 500px) {
  h1.dashboard-heade {
    font-size: 24px;
  }
  h1.dashboard-header::after {
    width: 90%;
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}
